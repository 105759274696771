import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'
import Sticky from 'react-sticky-state'
import { UAParser } from 'ua-parser-js'

// components
import { InputField } from '../../components/common/fields/FormFields'
import FaqItemTable from '../../components/faq/FaqItemTable'
import Loader from '../../components/common/Loader'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import Simulator from '../../containers/simulator/Simulator'
import Sidebar from '../../components/common/Sidebar'
import Trainer from '../bot/Trainer'

// actions
import { deleteCache } from '../../actions/api'
import { addNotice } from '../../actions/notice'
import { fetchAccount } from '../../actions/account'
import { fetchBot } from '../../actions/bot'
import { fetchFaq, updateFaq, createFaq, deleteFaq, exportFaq } from '../../actions/faq'
import { fetchFaqCategories } from '../../actions/faq_category'
import { fetchFaqItems } from '../../actions/faq_item'
import { fetchApplications } from '../../actions/application'
import { updateTableState } from '../../actions/table'

// helpers
import { isFetching } from '../../helpers/selector'
import { checkApplications } from '../../helpers/checkApplications'
import cancelUrl from '../../helpers/cancelurl'

const validate = data => {
  const errors = {}
  if (!data.name) {
    errors.name = 'validate.required'
  } else {
    if (data.name.length > 255) {
      errors.name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    }
  }

  return errors
}

export class FaqEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    bot: PropTypes.object,
    faq: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    applications: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      bot_id: PropTypes.string.isRequired,
      faq_id: PropTypes.string,
    }),
    isFetching: PropTypes.bool,
    currentChannel: PropTypes.string,
    tableState: PropTypes.object,
    maxFaqItems: PropTypes.number.isRequired,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const {
      dispatch,
      params: { bot_id },
    } = this.props
    this.refreshFaq()
    dispatch(fetchBot(state.session.token, bot_id))
    dispatch(fetchApplications(state.session.token, { original_bot_id: bot_id }))
    dispatch(fetchAccount(state.session.token))
    cancelUrl.setRouteLeaveHook(this)
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    clearTimeout(this.timer)
    dispatch(deleteCache('faq_items'))
  }

  refreshFaq = () => {
    if (!this.props.params.faq_id) return

    const state = this.context.store.getState()
    const { dispatch } = this.props
    const faq_id = parseInt(this.props.params.faq_id, 10)

    dispatch(fetchFaq(state.session.token, faq_id))
    dispatch(fetchFaqCategories(state.session.token, faq_id))
    dispatch(fetchFaqItems(state.session.token, faq_id))
  }

  handleSave = (data, dispatch) => {
    const state = this.context.store.getState()
    const { t, router } = this.context
    const {
      params: { faq_id, bot_id },
    } = this.props
    const faq = {
      bot_id,
      name: data.name,
    }

    if (faq_id) {
      faq.id = faq_id
      return dispatch(updateFaq(state.session.token, faq))
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
        .then(() => checkApplications(bot_id, this.props.applications, dispatch, this.context))
    } else {
      return dispatch(createFaq(state.session.token, faq))
        .then(json =>
          router.push({
            pathname: `/bots/${bot_id}/faqs/${json.result}`,
            state: { ignoreBlocking: true },
          })
        )
        .then(() => cancelUrl.setRouteLeaveHook(this))
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
        .then(() => checkApplications(bot_id, this.props.applications, dispatch, this.context))
    }
  }

  handleDelete = () => {
    const state = this.context.store.getState()
    const { t, router } = this.context
    const {
      applications,
      faq,
      dispatch,
      params: { bot_id },
    } = this.props
    if (!window.confirm(t('common.deleteConfirmMessage', { type: t('faqfile.faq.name'), name: faq.name })))
      return

    dispatch(deleteFaq(state.session.token, faq.id))
      .then(() => router.push({ pathname: `/bots/${bot_id}/faqs`, state: { ignoreBlocking: true } }))
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
      .then(() => checkApplications(bot_id, applications, dispatch, this.context))
  }

  handleExport = () => {
    const state = this.context.store.getState()
    const { dispatch, faq } = this.props
    return dispatch(exportFaq(state.session.token, faq.id)).then(blob => {
      // Download by special method if using IE
      if (new UAParser().getBrowser().name === 'IE') {
        window.navigator.msSaveBlob(blob, `${faq.name}.csv`)
      } else {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${faq.name}.csv`
        link.click()
      }
    })
  }

  handleAdd = () => {
    const { t, router } = this.context
    const {
      faq,
      items,
      dispatch,
      maxFaqItems,
      params: { bot_id },
    } = this.props
    if (items.length >= maxFaqItems) {
      dispatch(addNotice('warn', t('faqCategory.faqItemsLimitation', { maximum: maxFaqItems })))
      return
    }
    router.push(`/bots/${bot_id}/faqs/${faq.id}/items/new`)
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const {
      bot,
      faq,
      items,
      isFetching,
      submitting,
      handleSubmit,
      tableState,
      maxFaqItems,
      params: { bot_id },
    } = this.props
    const handleSave = handleSubmit(this.handleSave)

    return (
      <Loader loaded={!isFetching && !submitting} type="show">
        <div>
          <form className="text-left" onSubmit={handleSave}>
            {/* Faq name */}
            <div className="form-group">
              <LabelWithTooltip htmlFor="name" className="dm-title" name="faqfile.faq.name" />
              <Field
                name="name"
                type="text"
                className="form-control dm-form-control"
                maxLength="255"
                component={InputField}
              />
            </div>
          </form>

          {/* Faq Items */}
          {faq.id && (
            <div className="form-group dm-question">
              <FaqItemTable
                bot_id={bot_id}
                faq={faq}
                items={items}
                isFetching={isFetching}
                handleAdd={this.handleAdd}
                handleExport={this.handleExport}
                tableState={tableState}
                updateTableState={this.updateTableState}
                maxFaqItems={maxFaqItems}
              />
            </div>
          )}

          {/* Buttons to edit faq (sticked to the page buttom) */}
          <Sticky>
            <div className="sticky bottom">
              <div className="dm-sticky-buttons">
                <button
                  type="submit"
                  className="btn btn-primary dm-btn"
                  disabled={submitting}
                  onClick={handleSave}
                >
                  {t('common.save')}
                </button>
                {faq.id && (
                  <Trainer
                    bot={bot}
                    handleSubmit={handleSubmit}
                    handleSave={handleSave}
                    isSubmitting={submitting}
                  />
                )}
                {faq.id && (
                  <button
                    type="button"
                    className="btn btn-danger dm-btn"
                    onClick={this.handleDelete}
                    disabled={submitting}
                  >
                    {t('common.delete')}
                  </button>
                )}
              </div>
            </div>
          </Sticky>

          {/* Faq classifier simulator */}
          {faq.id && (
            <Sidebar titles={[t('simulator.title')]}>
              <Simulator tabs={['faqClassifier', 'chat']} faq={faq} bot={bot} />
            </Sidebar>
          )}
          {!faq.id && (
            <Sidebar titles={[t('simulator.title')]}>
              <Simulator tabs={['chat']} bot={bot} />
            </Sidebar>
          )}
        </div>
      </Loader>
    )
  }
}

const FaqEditForm = reduxForm({
  form: 'FaqEdit',
  enableReinitialize: true,
  validate,
})(FaqEdit)

export const mapStateToProps = (state, props) => {
  const account = lodash.first(Object.values(state.entities.accounts)) || {}
  const applications = lodash.filter(state.entities.applications)
  const bot = state.entities.bots[props.params.bot_id] || {}
  const faq = state.entities.faqs[props.params.faq_id] || {}
  const categories = lodash.filter(state.entities.faq_categories, { faq_id: faq.id })
  const categoryIds = categories.map(category => category.id)
  const items = lodash.filter(state.entities.faq_items, item =>
    lodash.includes(categoryIds, item.faq_category_id)
  )
  const initialValues = {
    ...faq,
  }

  return {
    bot: bot,
    faq: faq,
    items: items,
    applications: applications,
    isFetching: isFetching(state),
    currentChannel: state.chat.currentChannel,
    tableState: state.table[window.location.pathname],
    initialValues,
    maxFaqItems: account.max_faq_items,
  }
}

export default connect(mapStateToProps)(FaqEditForm)
