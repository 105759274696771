import lodash from 'lodash'
import { combineReducers } from 'redux'
// #!if !EMBEDDED
import requests from './requests'
import entities from './entities'
import pagination from './pagination'
import classifier from './classifier'
import integration from './integration'
import tour from './tour'
import news from './news'
import statistics from './statistics'
import table from './table'
import scenarioTree from './scenarioTree'
import groups from './groups'
// #!endif
import session from './session'
import notice from './notice'
import chat from './chat'

import { reducer as formReducer } from 'redux-form'

const appReducer = combineReducers({
  // #!if !EMBEDDED
  requests,
  entities,
  pagination,
  classifier,
  integration,
  tour,
  news,
  statistics,
  table,
  scenarioTree,
  groups,
  // #!endif
  session,
  notice,
  chat,
  form: formReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STATE' || action.type === 'DELETE_SESSION') {
    state = lodash.pick(state, ['news', 'table'])
  }

  return appReducer(state, action)
}

export default rootReducer
