import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'

import { SelectField } from '../common/fields/FormFields'

class NoticeModeSwitcher extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    noticeMode: PropTypes.string.isRequired,
    speakerNames: PropTypes.array,
    changeNoticeMode: PropTypes.func.isRequired,
    changeSpeaker: PropTypes.func.isRequired,
    stopReadingOutByVoiceSynth: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    aitalkEnabled: PropTypes.bool.isRequired,
    voicevoxEnabled: PropTypes.bool.isRequired,
    speechSynthesisDisabled: PropTypes.bool.isRequired,
  }

  changeNoticeMode = e => {
    const { changeNoticeMode, stopReadingOutByVoiceSynth, noticeMode, disabled } = this.props
    if (disabled) return

    const mode = e.currentTarget.value
    if (noticeMode === mode) return
    stopReadingOutByVoiceSynth()
    if (window.speechSynthesis) {
      window.speechSynthesis.cancel()
    }
    changeNoticeMode(mode)
  }

  changeSpeaker = e => {
    const { changeSpeaker, stopReadingOutByVoiceSynth } = this.props

    const speaker = e.currentTarget.value
    stopReadingOutByVoiceSynth()
    if (window.speechSynthesis) {
      window.speechSynthesis.cancel()
    }
    changeSpeaker(speaker)
  }

  render() {
    const { t } = this.context
    const {
      noticeMode,
      speakerNames,
      disabled,
      aitalkEnabled,
      voicevoxEnabled,
      speechSynthesisDisabled,
    } = this.props

    const defaultSpeakerNames = []
    if (window.speechSynthesis) {
      defaultSpeakerNames.push({ id: 'web_speech_api', name: t('embeddedChat.speakers.webSpeechApi') })
    }
    if (aitalkEnabled) {
      defaultSpeakerNames.push({ id: 'miyabi', name: t('embeddedChat.speakers.miyabi') })
      defaultSpeakerNames.push({ id: 'yamato', name: t('embeddedChat.speakers.yamato') })
      defaultSpeakerNames.push({ id: 'nozomi', name: t('embeddedChat.speakers.nozomi') })
      defaultSpeakerNames.push({ id: 'seiji', name: t('embeddedChat.speakers.seiji') })
      defaultSpeakerNames.push({ id: 'chihiro', name: t('embeddedChat.speakers.chihiro') })
      defaultSpeakerNames.push({ id: 'yuuto', name: t('embeddedChat.speakers.yuuto') })
    }
    if (voicevoxEnabled) {
      defaultSpeakerNames.push({ id: 'kasukabe_tsumugi', name: t('embeddedChat.speakers.kasukabeTsumugi') })
      defaultSpeakerNames.push({ id: 'amehare_hau', name: t('embeddedChat.speakers.amehareHau') })
      defaultSpeakerNames.push({ id: 'kurono_takehiro', name: t('embeddedChat.speakers.kuronoTakehiro') })
    }

    return (
      <React.Fragment>
        <div className="notice-mode-buttons">
          {!speechSynthesisDisabled && (
            <button
              type="button"
              value="speech"
              className={`btn ${noticeMode === 'speech' ? 'enabled' : ''}`}
              title={
                disabled
                  ? t('embeddedChat.noticeMode.unsupportedMessage')
                  : t('embeddedChat.noticeMode.speech')
              }
              disabled={disabled}
              onClick={this.changeNoticeMode}
            >
              <svg
                className="speech"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="28px"
                height="28px"
                viewBox="0 0 512 512"
                preserveAspectRatio="xMidYMid meet"
              >
                <g transform="translate(0, 512) scale(0.1, -0.1)" stroke="none">
                  <path d="M1275 4865 c-234 -37 -469 -138 -657 -281 -88 -68 -213 -197 -281 -291 -260 -363 -338 -813 -215 -1238 36 -126 179 -518 219 -602 65 -136 154 -259 273 -378 61 -61 141 -131 178 -155 l67 -45 1 -130 0 -131 -76 -37 c-384 -190 -656 -553 -748 -997 -14 -68 -36 -263 -36 -317 l0 -23 1507 0 1506 0 -7 111 c-35 582 -371 1068 -881 1274 -86 35 -96 41 -93 63 2 19 14 27 63 44 172 57 364 174 506 306 78 73 177 189 168 195 -2 2 -163 79 -358 172 -194 94 -353 175 -353 180 0 6 220 164 488 353 269 189 495 349 502 356 10 10 10 20 -3 55 -8 24 -15 49 -15 57 0 7 39 41 88 75 150 107 172 127 172 157 0 15 -4 33 -9 41 -5 7 -111 83 -237 170 l-229 156 -50 97 c-77 149 -139 233 -265 359 -216 217 -468 348 -768 399 -132 23 -332 25 -457 5z" />
                  <path d="M4340 3611 c-519 -291 -656 -371 -658 -388 -4 -30 45 -123 65 -123 20 0 1342 646 1362 665 12 12 9 26 -19 102 -38 103 -45 113 -74 112 -11 -1 -316 -166 -676 -368z" />
                  <path d="M3723 2418 c-19 -26 -45 -96 -41 -113 2 -11 239 -149 648 -377 355 -198 657 -365 672 -371 37 -15 47 -3 88 107 31 86 32 89 14 106 -11 9 -183 97 -384 195 -201 98 -497 243 -658 322 -162 78 -302 143 -312 143 -9 0 -22 -6 -27 -12z" />
                </g>
              </svg>
            </button>
          )}
          <button
            type="button"
            value="sound"
            className={`btn ${noticeMode === 'sound' ? 'enabled' : ''}`}
            title={
              disabled ? t('embeddedChat.noticeMode.unsupportedMessage') : t('embeddedChat.noticeMode.sound')
            }
            disabled={disabled}
            onClick={this.changeNoticeMode}
          >
            <svg
              className="sound"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="miter"
              fill="none"
            >
              <path d="M13 5v14l-5-4H3V9h5z" />
              <path strokeLinecap="round" d="M13 14c1.5-1 1.5-3 0-4" />
              <path d="M16 16C18.0858253 13.9141747 18.0858253 10.0858253 16 8M18 19C21.98552 15.01448 22.0076803 9.00768033 18 5" />
            </svg>
          </button>
          <button
            type="button"
            value="mute"
            className={`btn ${noticeMode === 'mute' ? 'enabled' : ''}`}
            title={t('embeddedChat.noticeMode.mute')}
            onClick={this.changeNoticeMode}
          >
            <svg
              className="mute"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="miter"
              fill="none"
            >
              <path d="M18 10L22 14M18 14L22 10" />
              <path d="M13 5v14l-5-4H3V9h5z" />
              <path strokeLinecap="round" d="M13 14c1.5-1 1.5-3 0-4" />
            </svg>
          </button>
        </div>
        {noticeMode === 'speech' && (aitalkEnabled || voicevoxEnabled) && (
          <React.Fragment>
            <div>
              <label className="item-title">{t('embeddedChat.speakers.title')}</label>
              <Field
                name="speaker"
                items={speakerNames ? speakerNames : defaultSpeakerNames}
                valueKey="id"
                displayKey="name"
                className="form-control dm-form-control speaker-select"
                component={SelectField}
                onChange={this.changeSpeaker}
              />
              {speakerNames && (
                <div className="license-link-wrapper">
                  <a
                    className="license-link"
                    href="/third-party-licenses.html#voice-synth-services"
                    target="_blank"
                  >
                    ライセンスを表示
                  </a>
                </div>
              )}
            </div>
            <hr />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default NoticeModeSwitcher
